<template>
  <div class="">
    <div class="pt-4 pb-2 px-3 hidden lg:flex lg:items-center lg:justify-between border-b border-solid border-gray-200" style="background-color: #1e1e2f; border-color: rgb(155, 155, 155);">
      <h1 class="text-xl text-white font-bold">Home Content Insurance</h1>
      <img src="@/assets/logo.svg" alt="logo" class="logo">
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition> 
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
input::placeholder{
  color: #697386
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>